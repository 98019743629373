<template>
  <div>
    <div
      class="py-12 md:h-128 bg-yellow-100 bg-opacity-50"
      :style="{
        background: 'url(\'/img/hero-bg.svg\') no-repeat center center',
      }"
    >
      <div
        class="h-full max-w-6xl mx-auto flex flex-col-reverse md:flex-row justify-between items-center"
      >
        <div class="mt-4 md:mt-0 md:w-2/5 lg:w-1/2">
          <img
            src="/img/hero.svg"
            class="w-full max-w-sm lg:max-w-none px-2 lg:px-12"
            alt="KnowMyRight"
          />
        </div>
        <div class="w-full md:w-3/5 lg:w-1/2">
          <div>
            <h2 class="text-3xl font-semibold text-primary-orange text-center">
              Ask Me a Question
            </h2>
            <div
              class="mt-5 pt-4 w-48 mx-auto border-t border-primary-orange"
            ></div>
            <p class="text-center text-sm">Discover your rights</p>
          </div>

          <div class="px-6 mt-6">
            <MultiSearch />
          </div>
        </div>
      </div>
    </div>

    <div class="md:max-w-4xl md:mx-auto px-6 md:px-0 py-12 md:px-0">
      <h2 class="mt-3 text-3xl font-semibold text-primary-orange text-center">
        Your Rights
      </h2>
      <div class="mt-5 pt-4 w-48 mx-auto border-t border-primary-orange"></div>
      <p class="text-center text-sm">Browse other topics to know more.</p>

      <div class="mt-8 grid grid-cols-8 gap-4">
        <div
          v-for="(topic, index) in topics"
          :key="`topic-${index}`"
          class="col-span-4 md:col-span-2"
        >
          <router-link
            :to="'/topics/' + topic.path"
            class="flex flex-col items-center pb-5 border border-gray-300 rounded-lg hover:border-gray-600 transition ease-in duration-150"
          >
            <div class="h-32 w-32 flex items-center justify-center">
              <img :src="`/img/topics/${topic.icon}`" :alt="topic.label" />
            </div>
            <p class="text-center">{{ topic.label }}</p>
          </router-link>
        </div>
      </div>
    </div>

    <!-- <div class="mt-8 bg-yellow-100 bg-opacity-50 py-12 px-6 md:px-0">
      <div class="max-w-6xl mx-auto">
        <h2 class="text-3xl font-semibold text-primary-orange text-center">
          About Us
        </h2>
        <div
          class="mt-5 pt-4 w-48 mx-auto border-t border-primary-orange"
        ></div>
        <p class="text-center text-sm">
          Watch our video to know more about us and how to use this website.
        </p>
        <div
          class="max-w-lg md:max-w-3xl mx-auto mt-6 h-64 md:h-96 bg-gray-200 shadow"
        ></div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Topics from "@/mixins/Topics";
import MultiSearch from "@/components/MultiSearch";

export default {
  mixins: [Topics],
  components: {
    MultiSearch,
  },
  name: "Home",
  data: () => ({}),
};
</script>
